.block-user-input {
  pointer-events: none;
  user-select: none;
}

#PageOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  text-align: center;
  z-index: 2147483647;
  pointer-events: none;
  user-select: none;
  background-color: #fff;
  opacity: 0.2;
}

#Loader {
  position: relative;
  margin: 0 auto;
  display: flex;
  align-items: center;
  border: 0.5rem solid #c8d1e0;
  border-top-color: #1d1f24;
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
