.ng-select {
  position: relative;
  display: block;
  box-sizing: border-box;
}

.no-gutter {
  padding: 0;
}

.ng-select div,
.ng-select input,
.ng-select span {
  box-sizing: border-box;
}

.ng-select [hidden] {
  display: none;
}

.ng-select-container,
.ng-select.ng-select-searchable,
.ng-value-container,
.ng-input {
  opacity: 1;
}

.ng-select .ng-select-container {
  background: #f5f7fa !important;
  cursor: default;
  display: flex;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.ng-select-container .ng-select.ng-select-opened .ng-select-container {
  z-index: 1001;
}

.ng-select.ng-select-disabled,
.ng-select-container,
.ng-value-container,
.ng-placeholder,
.ng-value {
  user-select: none;
  cursor: default;
}

.ng-select .ng-arrow-wrapper {
  cursor: pointer;
  position: relative;
  text-align: center;
  user-select: none;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  pointer-events: none;
  display: inline-block;
  height: 0;
  width: 0;
  position: relative;
}

.ng-select.ng-select-disabled .ng-arrow-wrapper {
  cursor: default;
}

.ng-select.ng-select-filtered .ng-placeholder {
  display: none;
}

.ng-select .ng-select-container .ng-value-container {
  display: flex;
  flex: 1;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  box-sizing: content-box;
  background: none transparent;
  border: 0 none;
  box-shadow: none;
  outline: none;
  padding: 0;
  cursor: default;
  width: 100%;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  white-space: nowrap;
}

.ng-select.ng-select-single.ng-select-filtered .ng-select-container .ng-value-container .ng-value {
  visibility: hidden;
}

.visually-hidden {
  display: none !important;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value-icon {
  display: none;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  position: absolute;
  left: 0;
  width: 100%;
}

.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value-icon {
  display: none;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  flex-wrap: wrap;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  position: absolute;
  z-index: 1;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  cursor: pointer;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-icon {
  display: none;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  flex: 1;
  z-index: 2;
}

.ng-select .ng-clear-wrapper {
  cursor: pointer;
  position: relative;
  width: 17px;
  user-select: none;
}

.ng-select .ng-clear-wrapper .ng-clear {
  display: inline-block;
  font-size: 18px;
  line-height: 1;
  pointer-events: none;
}

.ng-select .ng-spinner-loader {
  border-radius: 50%;
  width: 17px;
  height: 17px;
  margin-right: 5px;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 2px solid rgba(66, 66, 66, 20%);
  border-right: 2px solid rgba(66, 66, 66, 20%);
  border-bottom: 2px solid rgba(66, 66, 66, 20%);
  border-left: 2px solid #424242;
  transform: translateZ(0);
  animation: load8 0.8s infinite linear;
}

.ng-select .ng-spinner-loader::after {
  border-radius: 50%;
  width: 17px;
  height: 17px;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.ng-dropdown-panel {
  box-sizing: border-box;
  position: absolute;
  opacity: 0;
  width: 100%;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
}

.ng-dropdown-panel .ng-dropdown-panel-items {
  display: block;
  height: auto;
  box-sizing: border-box;
  max-height: 240px;
  overflow-y: auto;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .highlighted {
  font-weight: bold;
  text-decoration: underline;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.disabled {
  cursor: default;
}

.ng-dropdown-panel .scroll-host {
  overflow: hidden;
  overflow-y: auto;
  position: relative;
  display: block;
  -webkit-overflow-scrolling: touch;
}

.ng-dropdown-panel .scrollable-content {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.ng-dropdown-panel .total-padding {
  width: 1px;
  opacity: 0;
}
