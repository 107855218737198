.select-container {
  position: relative;
  width: 200px;
}

.form-select-custom {
  appearance: none;
  text-indent: 1px;
  text-overflow: '';
  width: 100%;
  padding: 6px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  font-size: 16px;
  color: #333;
}

.select-container::after {
  content: '\25BC';
  font-size: 16px;
  color: #333;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

.form-select-custom:focus {
  outline: none;
  border-color: #66afe9;
  box-shadow: 0 0 5px rgba(102, 175, 233, 50%);
}