.switch-container .switch input:checked + .slider::before {
  transform: translateX(13px);
  background-color: white;
  left: 3px;
}

input:disabled + .slider::before {
  background-color: #ccc;
  left: 1px;
}

.switch-container {
  display: flex;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 17px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  inset: 0;
  background-color: white;
  transition: 0.4s;
  border-radius: 17px;
}

.slider::before {
  position: absolute;
  content: '';
  height: 13px;
  width: 13px;
  left: 3px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input + .slider {
  background-color: white;
  border: 1px solid #ccc;
}

input:checked + .slider {
  background-color: #2866a2;
  border: 1px solid #2866a2;
}

input:focus + .slider {
  box-shadow: 0 0 1px #24598f;
  border: 1px solid #2866a2;
}
